import { constantCase } from 'change-case';
import type { Shipment } from '@xbcb/api-gateway-client';
import { getRecordType } from '@xbcb/core';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { WorkOrderType } from '@xbcb/work-order-types';

export const findShipmentWorkOrdersFromWorkOrderType = (
  shipment: Shipment,
  workOrderType: WorkOrderType,
): AnyObject[] =>
  shipment.workOrderGroup.workOrders.filter(
    ({ id }: { id: string }) =>
      getRecordType(id) === (constantCase(workOrderType) as RecordType),
  );
