import React from 'react';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { NamePath, CssSize } from '@xbcb/ui-types';
import { FormItem, Select } from '@xbcb/form-item-components';
import { ActivationStatus } from '@xbcb/party-types';

type ActivationInfoProps = {
  fullNamePath: NamePath;
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  workOrderBrokerId: string;
};

const ActivationInfo = ({
  fullNamePath,
  form,
  readOnly,
  disabled,
  workOrderBrokerId,
}: ActivationInfoProps) => {
  return (
    <Form.List name={fullNamePath} minimum={1}>
      {(fields) => {
        return (
          <>
            {fields.map((field) => {
              const statusLocalNamePath = [field.name, 'status'];
              const customsBrokerLocalNamePath = [
                field.name,
                'customsBroker',
                'id',
              ];
              const status = form.getFieldValue([
                ...fullNamePath,
                ...statusLocalNamePath,
              ]);
              const isRejected = Boolean(status === ActivationStatus.REJECTED);
              const customsBrokerId = form.getFieldValue([
                ...fullNamePath,
                ...customsBrokerLocalNamePath,
              ]);
              return (
                customsBrokerId === workOrderBrokerId && (
                  <>
                    <FormItem
                      $itemSize={CssSize.TINY_SHORT}
                      $inline
                      name={[field.name, 'brokerRepresentation']}
                      label="Broker Representation"
                      $readOnly
                    >
                      <Input disabled />
                    </FormItem>
                    <FormItem
                      $itemSize={CssSize.SHORT}
                      name={statusLocalNamePath}
                      label="Current Status"
                      $inline
                      $readOnly={readOnly}
                    >
                      <Input disabled={disabled} />
                    </FormItem>
                    {isRejected && (
                      <FormItem
                        name={[field.name, 'rejectionReasons']}
                        label="Rejection Reasons"
                        $itemSize={CssSize.MEDIUM}
                        $inline
                        $readOnly={readOnly}
                      >
                        <Select
                          disabled={disabled}
                          mode="tags"
                          listHeight={20}
                          tokenSeparators={[',']}
                        />
                      </FormItem>
                    )}
                  </>
                )
              );
            })}
          </>
        );
      }}
    </Form.List>
  );
};

export default ActivationInfo;
