import type { Shipment } from '@xbcb/api-gateway-client';
import { AnyObject } from '@xbcb/shared-types';
import { WorkOrderStatus, WorkOrderType } from '@xbcb/work-order-types';
import {
  usIorContinuousBondRequestEvaluator,
  usIsfEvaluator,
  usPostSummaryCorrectionEvaluator,
} from './evaluators';
import { findShipmentWorkOrdersFromWorkOrderType } from './findShipmentWorkOrdersFromWorkOrderType';
import {
  transformToDeliveryOrderInput,
  transformToUsConsumptionEntryInput,
  transformToUsInBondInput,
  transformToUsIorContinuousBondRequestInput,
  transformToUsIsfInput,
  transformToUsPostSummaryCorrection,
  transformToUsType86EntryInput,
} from './transformers';

export const workOrderTypeToTransformerMap: {
  [key in WorkOrderType]?: {
    transformer: (shipment: Shipment) => AnyObject;
    evaluator?: (shipment: Shipment) => boolean;
  };
} = {
  [WorkOrderType.UsConsumptionEntry]: {
    transformer: transformToUsConsumptionEntryInput,
  },
  [WorkOrderType.UsInBond]: {
    transformer: transformToUsInBondInput,
  },
  [WorkOrderType.UsIsf]: {
    transformer: transformToUsIsfInput,
    evaluator: usIsfEvaluator,
  },
  [WorkOrderType.DeliveryOrder]: {
    transformer: transformToDeliveryOrderInput,
  },
  [WorkOrderType.UsPostSummaryCorrection]: {
    transformer: transformToUsPostSummaryCorrection,
    evaluator: usPostSummaryCorrectionEvaluator,
  },
  [WorkOrderType.UsType86Entry]: {
    transformer: transformToUsType86EntryInput,
  },
  [WorkOrderType.UsIorContinuousBondRequest]: {
    transformer: transformToUsIorContinuousBondRequestInput,
    evaluator: usIorContinuousBondRequestEvaluator,
  },
};

export type ShipmentAndWorkOrderType = {
  shipment: Shipment;
  workOrderType: WorkOrderType;
};

export const transformShipmentToWorkOrderInput = ({
  shipment,
  workOrderType,
}: ShipmentAndWorkOrderType): AnyObject => {
  const { transformer } = workOrderTypeToTransformerMap[workOrderType] || {};
  return transformer ? transformer(shipment) : {};
};

export const evaluateWorkOrderTypeOnShipment = ({
  shipment,
  workOrderType,
}: ShipmentAndWorkOrderType): boolean => {
  const workOrders = findShipmentWorkOrdersFromWorkOrderType(
    shipment,
    workOrderType,
  );
  // Prevent work order creation if at least one work order is not canceled
  if (
    workOrders.find(
      (workOrder) =>
        ![WorkOrderStatus.CANCELED, WorkOrderStatus.REPLACED].includes(
          workOrder.status,
        ),
    )
  ) {
    return false;
  }
  const evaluator = workOrderTypeToTransformerMap[workOrderType]?.evaluator;
  if (evaluator) {
    return evaluator(shipment);
  }
  return true;
};
