import {
  Shipment,
  CreateDeliveryOrderInput,
  UsConsumptionEntry,
} from '@xbcb/api-gateway-client';
import { transformShipmentToDeliveryOrderInput } from '@xbcb/work-order-utils/dist/shipmentToWorkOrder/transformShipmentToDeliveryOrderInput';
import { getRecordType } from '@xbcb/core';
import { RecordType } from '@xbcb/shared-types';

export const transformToDeliveryOrderInput = ({
  workOrderGroup: { id: wogId, workOrders },
}: Shipment): CreateDeliveryOrderInput => {
  const entry = workOrders.find(
    ({ id }) => getRecordType(id) === RecordType.US_CONSUMPTION_ENTRY, // This would need to be changed for non-US entries
  ) as UsConsumptionEntry;

  return transformShipmentToDeliveryOrderInput({
    wogId,
    entry,
  });
};
