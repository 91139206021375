import type { Shipment } from '@xbcb/api-gateway-client';
import { transformShipmentLegToUsIsfInput } from '@xbcb/work-order-utils/dist/shipmentToWorkOrder/transformShipmentLegToUsIsfInput';

export const transformToUsIsfInput = ({
  legs,
  clientIdentifier,
  workOrderGroup: { id: wogId },
  id: shipmentId,
  operator: { id: operatorId },
  buyer,
  sellers,
  manufacturers,
  shipTo,
  stuffingLocation,
  consolidator,
}: Shipment) =>
  transformShipmentLegToUsIsfInput({
    // We assume when creating a WO via the UI like this it will always be the first leg
    shipmentLeg: legs[0],
    clientIdentifier,
    wogId,
    shipmentId,
    operatorId,
    buyer,
    sellers,
    manufacturers,
    shipTo,
    stuffingLocation,
    consolidator,
    // Provide an empty map, as from the UI we don't provide this info
    complianceRefToProductMap: {},
  });
