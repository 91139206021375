import type { Shipment } from '@xbcb/api-gateway-client';
import { WorkOrderType } from '@xbcb/work-order-types';
import { findShipmentWorkOrdersFromWorkOrderType } from '../findShipmentWorkOrdersFromWorkOrderType';

export const usPostSummaryCorrectionEvaluator = (
  shipment: Shipment,
): boolean => {
  // We should only allow creation if there is an entry
  return Boolean(
    findShipmentWorkOrdersFromWorkOrderType(
      shipment,
      WorkOrderType.UsConsumptionEntry,
    ).length,
  );
};
