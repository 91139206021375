import type { Shipment } from '@xbcb/api-gateway-client';
import { transformShipmentLegToUsType86EntryInput } from '@xbcb/work-order-utils/dist/shipmentToWorkOrder/transformShipmentLegToUsType86EntryInput';

export const transformToUsType86EntryInput = ({
  legs,
  clientIdentifier,
  workOrderGroup: { id: wogId },
  id: shipmentId,
  operator: { id: operatorId },
  sellers,
  shipTo,
  badges,
}: Shipment) =>
  transformShipmentLegToUsType86EntryInput({
    // We assume when creating a WO via the UI like this it will always be the first leg
    shipmentLeg: legs[0],
    clientIdentifier,
    wogId,
    shipmentId,
    operatorId,
    sellers,
    shipTo,
    // Provide an empty map, as from the UI we don't provide this info
    complianceRefToProductMap: {},
    badges,
  });
