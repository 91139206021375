import { initButter, startIntercom, initIntercom } from '@xbcb/ui-utils';
import { getIsHostCbms } from 'libs/getIsHostCbms';

const initServices = (): void => {
  initButter();
  initIntercom();
  if (getIsHostCbms()) startIntercom();
};

export default initServices;
