import type { Shipment } from '@xbcb/api-gateway-client';
import {
  CONTINUOUS_BOND_AUTOMATION_FEATURE,
  continuousBondAutomationFeature,
} from '@xbcb/feature-flags';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import { WorkOrderType } from '@xbcb/work-order-types';
import { findShipmentWorkOrdersFromWorkOrderType } from '../findShipmentWorkOrdersFromWorkOrderType';

const isContinuousBondAutomationEnabled =
  CONTINUOUS_BOND_AUTOMATION_FEATURE.isEnabled(
    continuousBondAutomationFeature,
    {
      stage: uiStageToBackendStage[getEnv().stage],
    },
  );

export const usIorContinuousBondRequestEvaluator = (
  shipment: Shipment,
): boolean => {
  if (!isContinuousBondAutomationEnabled) {
    return false;
  }
  // We should only allow creation if there is an entry
  return Boolean(
    findShipmentWorkOrdersFromWorkOrderType(
      shipment,
      WorkOrderType.UsConsumptionEntry,
    ).length,
  );
};
