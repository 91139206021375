import type { Shipment } from '@xbcb/api-gateway-client';
import { safeGet } from '@xbcb/js-utils';

export const transformToUsIorContinuousBondRequestInput = (
  shipment: Shipment,
) => ({
  group: {
    id: safeGet(shipment, 'workOrderGroup.id'),
  },
  shipper: {
    id:
      safeGet(shipment, 'workOrderGroup.shipper.id') ||
      safeGet(shipment, 'shipper.shipper.id'),
  },
  usIor: {
    id: safeGet(shipment, ['legs', 0, 'ior', 'ior', 'id']),
  },
});
