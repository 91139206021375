import { createAction } from '@reduxjs/toolkit';
import { RootState } from '../reducers';
import {
  USER_LOGIN_CHANGE,
  AVATAR_CHANGE,
  USER_CHANGE_TYPE,
  LOGOUT,
  UserLoginPayload,
  TablesState,
} from '../types';
import type { Team } from '@xbcb/api-gateway-client';
import {
  defaultTables as defaultTablesFunc,
  getColumnOptions,
} from '@xbcb/table-utils';
import SHOW_NEW_CONTENT_MODAL from 'libs/showNewContentModal';
import { Modal } from 'antd';
import moment from 'moment';
import { Cognito } from '@xbcb/aws-utils';
import { client } from '@xbcb/apollo-client';
import {
  getEnv,
  getCodes,
  refreshCodes,
  loadCodes,
  startIntercom,
  reportError,
  hideIntercom,
} from '@xbcb/ui-utils';
import { timeout } from '@xbcb/js-utils';
import { AccountType, ObjectType, TagKey, TagValue } from '@xbcb/shared-types';
import { codeVersionChange, tablesUpdate, MQTT_CONNECT } from '.';
import { UiStage } from '@xbcb/ui-types';
import { PROFILE_CACHE } from './profile';
import { Login } from 'libs/sharedQueries';
import { getUserAccountType } from '@xbcb/client-utils';
import { getIsHostCbms } from 'libs/getIsHostCbms';
import { awsRumClient } from '@xbcb/cloudwatch-rum';
import { isMonsEnv } from '@xbcb/ui-env';

const isMons = isMonsEnv();

export const userLoginChange = createAction<
  UserLoginPayload,
  typeof USER_LOGIN_CHANGE
>(USER_LOGIN_CHANGE);

export const avatarChange = createAction<string, typeof AVATAR_CHANGE>(
  AVATAR_CHANGE,
);

export const userChangeType = createAction<string, typeof USER_CHANGE_TYPE>(
  USER_CHANGE_TYPE,
);

export const logout = createAction<void, typeof LOGOUT>(LOGOUT);

const login = async (userIdentifier?: string) =>
  await client.query({
    query: Login,
    variables: { cognitoSub: userIdentifier },
  });

export const loginAsync =
  (cognitoAttributes: UserLoginPayload) =>
  async (dispatch: any, getState: () => RootState): Promise<boolean> => {
    const {
      codeVersion: { versionId: currentVersion },
      tables = {},
    } = getState();
    const { stage } = getEnv();
    let cbmsAttributes: { [key: string]: any } = {};
    try {
      if (!isMons) {
        const authenticated = await Cognito.authUser({ force: true });
        if (!authenticated) throw new Error('Could not authenticate user');
        awsRumClient.setCredentials();
      }
      const promises = [];

      await loadCodes();

      const run = () =>
        refreshCodes({
          type: 'app',
          client,
          currentVersion,
          dispatch,
          CODE_VERSION_CHANGE: codeVersionChange,
        });
      if (!currentVersion || !getCodes()) {
        promises.push(run());
      } else {
        promises.push(
          new Promise<void>((resolve) => {
            run();
            resolve();
          }),
        );
      }

      const { userToken } = cognitoAttributes;
      const isOnboardingUser = ['onboarding', 'pending'].includes(
        cognitoAttributes['custom:type'] as string,
      );
      // No need to graphql login if user is in onboarding or migration status
      // which customType is onboarding or pending
      if (!isOnboardingUser && window.location.pathname !== '/migrate') {
        const inltLogin = async () => {
          try {
            return await loginCognitoHelper(userToken, dispatch);
          } catch (e) {
            if ((e?.response?.data?.message || '').startsWith('Signature')) {
              Modal.error({
                title: "Your computer's clock is out of sync",
                content:
                  'For security reasons, we require your clock to be accurate. Please update your clock, typically by right clicking the time and clicking "adjust date/time." If you continue to have issues please contact us via chat, email, or phone to resolve.',
              });
            }
            reportError(e);
            throw e;
          }
        };
        promises.push(inltLogin());
      }

      const resolvedPromises = await Promise.all(promises);
      cbmsAttributes = resolvedPromises[1] as any;
      const { user, intercomHash, forwarderId } = cbmsAttributes ?? {};
      if (cbmsAttributes) {
        dispatch(PROFILE_CACHE(cbmsAttributes));
      }
      const profile = {
        ...cognitoAttributes,
        userId: isMons ? user?.email : await Cognito.getUserId(),
      };
      dispatch(userLoginChange(profile));

      // Onboarding user should use onboarding type as accountType
      const accountType =
        getUserAccountType(user?.assumedUser?.id || user?.id) ||
        (cognitoAttributes['custom:onboardingType'] as AccountType);
      // If this is ever true then we probably added a new user type and missed adding it in the map in getUserAccountType
      if (!accountType) {
        throw new Error('Unknown account type for the user');
      }

      getUserTables(accountType, tables, dispatch);

      let email = user?.email;
      if (
        !email &&
        cognitoAttributes.email &&
        typeof cognitoAttributes.email === 'string'
      )
        email = cognitoAttributes.email;
      const userId = profile?.userId;
      let createdTime = user?.created?.time;
      createdTime = createdTime
        ? parseInt(moment.utc(createdTime).format('X'), 10)
        : undefined;
      const { name, title, officePhone, mobilePhone } = user || {};

      const phoneNumber = officePhone?.number || mobilePhone?.number;

      const teams = user?.teams as Team[] | undefined;
      // Is an "AGL Operator user" if they only have one team and that team is AGL
      const isAglOperatorUser = Boolean(
        accountType === AccountType.OPERATOR &&
          teams?.find(({ tags }) =>
            tags.find(
              ({ key, value }) =>
                key === TagKey.STRATEGIC_BUSINESS_UNIT &&
                value === TagValue.AMAZON_GLOBAL_MILE_FORWARDING,
            ),
          ) &&
          teams?.length === 1,
      );
      if (stage === UiStage.PROD) {
        // Intercom should work in prod for everyone except FBA operators
        if (isAglOperatorUser) {
          hideIntercom();
        } else {
          const identify = {
            name,
            title,
            email,
            phone: phoneNumber,
            user_id: userId,
            created_at: createdTime,
            user_hash: intercomHash,
          };
          const owner = getState().ui.owner as { entityId: string };
          if (window.Intercom && window.Intercom.booted) {
            let w = 0;
            while (!owner.entityId && w < 200) {
              w++;
              await timeout(10);
            }
            if (!getIsHostCbms() && forwarderId === owner.entityId) {
              startIntercom(identify);
            } else {
              window.Intercom('update', identify);
            }
          }
        }
      }

      if (
        cognitoAttributes['custom:type'] &&
        !['onboarding', 'pending'].includes(cognitoAttributes['custom:type'])
      ) {
        const userId = user?.id || '';
        if (!isMons) dispatch(MQTT_CONNECT(userId));
      }
      return true;
    } catch (e) {
      reportError(e);
      if (!isMons) Cognito.signOutUser();
      return false;
    }
  };

const getUserTables = (
  accountType: AccountType,
  tables: TablesState,
  dispatch: any,
) => {
  const defaultTables = defaultTablesFunc(accountType);
  // this accounts for a new table to defaultTables and the case of a user having a clean cache (e.g. a new user)
  const newTables = JSON.parse(JSON.stringify({ ...defaultTables, ...tables }));

  // remove/reset any invalid fields (might have been cached in the users browser in a previous session)
  Object.keys(newTables).forEach((objType: string) => {
    const objectType = objType as ObjectType;
    if (!defaultTables[objectType]) {
      // invalid object type
      delete newTables[objectType];
    } else {
      const validColumns = getColumnOptions({
        objectType,
        accountType,
      });

      const table = newTables[objectType];

      // We should never allow there to be zero columns in a table. Thus, if
      // it does, set the columns equal to the defaultTable's columns
      const defaultTable = defaultTables[objectType];
      if (table.columns?.length === 0 && defaultTable) {
        table.columns = defaultTable.columns;
      }

      // invalid columns
      if (table.columns) {
        table.columns = table.columns.filter((column: string) =>
          validColumns.includes(column),
        );
      }
      // invalid sortField
      if (table.sortField) {
        if (!validColumns.includes(table.sortField)) {
          table.sortField = defaultTables[objectType].sortField;
        }
      }
    }
  });
  dispatch(tablesUpdate(newTables));
};

const loginCognitoHelper = async (
  userToken: string | undefined,
  dispatch: any,
) => {
  const result = await login(userToken);
  const user = result?.data?.login || {};
  const { minimumAppVersion, latestAppVersion } = user;
  await dispatch(
    SHOW_NEW_CONTENT_MODAL({
      minimumAppVersion,
      latestAppVersion,
    }),
  );
  return user;
};
