import type {
  UsConsumptionEntryLine,
  UsCbpEntryLineTaxOption,
} from '@xbcb/api-gateway-client';
import { aphLpcoList } from '@xbcb/entry-utils/dist/lib/aphLpcoList';
import { AnyObject } from '@xbcb/shared-types';
import { LpcoFields } from 'components/Lpco/lpcoFields';

/**
 * If line.taxOptions is set via HTS, it will have a type based on hts.fee.class or similar.
 * If this is absent, we have bad data introduced at the Product level
 * that will cause errors on the backend and prevent entry submission.
 * We should (TODO: https://app.asana.com/0/0/1203885455905200/f) clean up any remaining
 * bad data and prevent taxOptions from being set in this way.
 * But it is always safe to ignore broken data like this, since HTS records should
 * be the source of truth for all taxes and fees related to a product.
 *
 * To handle preexisting broken data, filter out invalid taxOptions to prevent errors
 * that block entry submission.
 */

const cleanTaxOptions = (taxOptions?: UsCbpEntryLineTaxOption[]) => {
  const options = taxOptions?.filter((taxOption) => Boolean(taxOption.type));
  return options?.length ? options : null;
};

export const transformLines = {
  toForm: ({
    lines,
  }: {
    lines?: UsConsumptionEntryLine[];
  }): UsConsumptionEntryLine[] | undefined => {
    if (lines) {
      lines.forEach((line) => {
        if (line.tariffs) {
          line.tariffs.forEach((tariff: AnyObject) => {
            if (tariff.pga) {
              for (const key of Object.keys(tariff.pga)) {
                const pga = tariff.pga[key];
                // add disclaim field
                if (pga?.disclaimerCode) {
                  pga.disclaim = true;
                }
                // add exempt field for foodFacilityRegistration
                if (pga?.foodFacilityRegistration?.exemption) {
                  pga.foodFacilityRegistration.exempt = true;
                }
                // add exempt field for LPCO
                for (const field of Object.values(LpcoFields)) {
                  if (pga && pga[field]?.exemptionCode) {
                    pga[field].exempt = true;
                  }
                }
                // add exempt field for Label Approval
                if (pga?.certificateOfLabelApproval?.exemptionCode) {
                  pga.certificateOfLabelApproval.exempt = true;
                }
                // APH Core LPCO
                if (pga && (key === 'AQ1' || key === 'AQ2')) {
                  pga['LPCO'] = [];
                  for (const { fieldName } of aphLpcoList) {
                    const lpco = pga[fieldName];
                    if (lpco) {
                      delete pga[fieldName];
                      pga['LPCO'].push({ lpcoFieldName: fieldName, ...lpco });
                    }
                  }
                }
              }
            }
          });
        }
      });
    }
    return lines;
  },

  toSchema: ({ lines }: { lines?: any }): UsConsumptionEntryLine[] => {
    if (lines) {
      lines.forEach((line: any) => {
        // adCvdCase is IdInput, thus remove version
        if (line.adCase) {
          delete line.adCase.version;
        }
        if (line.cvCase) {
          delete line.cvCase.version;
        }
        // change productClaimCode type to string
        if (line.productClaimCode) {
          line.productClaimCode = 'C';
        }
        if (line.taxOptions) {
          line.taxOptions = cleanTaxOptions(line.taxOptions);
        }
        if (line.tariffs) {
          line.tariffs.forEach((tariff: any) => {
            if (tariff.unitValue && !tariff.unitValue.currency) {
              tariff.unitValue = {
                currency: 'USD',
                value: tariff.unitValue.value,
              };
            }
            if (tariff.pga) {
              for (const key of Object.keys(tariff.pga)) {
                const pga = tariff.pga[key];
                if (pga) {
                  // delete disclaim field
                  delete pga.disclaim;
                  // delete exempt field for foodFacilityRegistration
                  if (pga?.foodFacilityRegistration) {
                    delete pga.foodFacilityRegistration.exempt;
                  }
                  // delete exempt field for LPCO
                  for (const field of Object.values(LpcoFields)) {
                    if (pga && pga[field]) {
                      delete pga[field].exempt;
                    }
                  }
                  // delete exempt field for Label Approval
                  if (pga?.certificateOfLabelApproval) {
                    delete pga.certificateOfLabelApproval.exempt;
                  }
                  // add complianceCode.type field for radiationDeclaration only if we have complianceCode.value data
                  const declarationCode =
                    pga?.radiationDeclaration?.declarationCode;
                  const reasonCode = pga?.radiationDeclaration?.reasonCode;
                  const complianceCodeValue =
                    pga?.radiationDeclaration?.complianceCode?.value;
                  if (complianceCodeValue && declarationCode && reasonCode) {
                    const complianceCodeType = `R${declarationCode}${reasonCode}`;
                    pga.radiationDeclaration.complianceCode.type =
                      complianceCodeType;
                  } else {
                    // if either value, declarationCode, or reasonCode are missing, delete complianceCode.
                    delete pga?.radiationDeclaration?.complianceCode;
                  }

                  // APH Core LPCO
                  if (key === 'AQ1' || key === 'AQ2') {
                    if (pga.LPCO) {
                      for (const item of pga.LPCO) {
                        const lpcoFieldName = item.lpcoFieldName;
                        delete item.lpcoFieldName;
                        pga[lpcoFieldName] = item;
                      }
                    }
                    delete pga.LPCO;
                  }
                }
              }
            }
          });
        }
      });
    }
    return lines;
  },
};
