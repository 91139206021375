import type { Shipment } from '@xbcb/api-gateway-client';
import { transformShipmentLegToUsInBondInput } from '@xbcb/work-order-utils/dist/shipmentToWorkOrder/transformShipmentLegToUsInBondInput';

export const transformToUsInBondInput = ({
  legs,
  clientIdentifier,
  workOrderGroup: { id: wogId },
  id: shipmentId,
  operator: { id: operatorId },
  badges,
}: Shipment) =>
  transformShipmentLegToUsInBondInput({
    // We assume when creating a WO via the UI like this it will always be the first leg
    shipmentLeg: legs[0],
    clientIdentifier,
    wogId,
    shipmentId,
    operatorId,
    badges,
  });
