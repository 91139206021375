import { capitalCase } from 'change-case';
import { formatAbbreviation } from 'libs/formatAbbreviations';

export const codeToText = (code: string): string => {
  let noPrefix = '';
  const usIsfPrefix = 'US_ISF_';
  const usConsumptionEntryPrefix = 'US_CONSUMPTION_ENTRY_';
  const usIorActivationPrefix = 'US_IOR_ACTIVATION_';
  const usInBondPrefix = 'US_IN_BOND_';
  const euCustomsEntryPrefix = 'EU_CUSTOMS_ENTRY_';

  if (code.startsWith(usIsfPrefix)) {
    noPrefix = code.replace(usIsfPrefix, '');
  } else if (code.startsWith(usConsumptionEntryPrefix)) {
    noPrefix = code.replace(usConsumptionEntryPrefix, '');
  } else if (code.startsWith(usIorActivationPrefix)) {
    noPrefix = code.replace(usIorActivationPrefix, '');
  } else if (code.startsWith(usInBondPrefix)) {
    noPrefix = code.replace(usInBondPrefix, '');
  } else if (code.startsWith(euCustomsEntryPrefix)) {
    noPrefix = code.replace(euCustomsEntryPrefix, '');
  } else {
    noPrefix = code;
  }
  return formatAbbreviation(capitalCase(noPrefix));
};
