import type { Shipment } from '@xbcb/api-gateway-client';
import { getRecordType } from '@xbcb/core';
import { RecordType } from '@xbcb/shared-types';
import { transformUsConsumptionEntryToUsPostSummaryCorrectionInput } from '@xbcb/work-order-utils/dist/transformUsConsumptionEntryToUsPostSummaryCorrectionInput';

export const transformToUsPostSummaryCorrection = ({
  workOrderGroup,
}: Shipment) => {
  const entry = workOrderGroup.workOrders.find(
    ({ id }) => getRecordType(id) === RecordType.US_CONSUMPTION_ENTRY,
  );
  if (!entry) return {};
  return transformUsConsumptionEntryToUsPostSummaryCorrectionInput(
    // TODO change to `as UsConsumptionEntry` once binding is updated
    entry as any,
  );
};
