import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Skeleton, Button } from 'antd';
import { capitalCase } from 'change-case';
import { find, without } from 'lodash';
import moment from 'moment';
import { useBundle } from '@amzn/react-arb-tools';
import AssignWorkOrderTaskModal from 'components/AssignWorkOrderTaskModal';
import { PageContent } from '@xbcb/display-components';
import CompleteWorkOrderTaskModal from 'components/CompleteWorkOrderTaskModal';
import RightContentSpace from 'components/RightContentSpace';
import WorkOrderInfoCard, { WorkOrderInfo } from 'components/WorkOrderInfoCard';
import WorkOrderTabs from 'components/WorkOrderTabs';
import {
  useCurrentUser,
  useQueryParams,
  useWorkOrderTaskType,
  QueryString,
} from 'libs/hooks';
import { WorkOrderTaskStatus, WorkOrderTaskType } from '@xbcb/work-order-types';
import { StyledResult } from './styles';
import { AccountType, RecordType } from '@xbcb/shared-types';
import {
  getKeyForWorkOrderTabConfiguration,
  getWorkOrderTabConfiguration,
  DEFAULT,
} from 'libs/workOrderTabConfiguration';
import { uiStageToBackendStage, WorkOrderTab } from '@xbcb/ui-types';
import { AppRecordProvidedProps } from 'routes';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import CancelWorkOrderModal from 'components/CancelWorkOrderModal';
import { WorkOrderTaskEvaluatedState } from 'libs/evaluateWorkOrderTaskState';
import {
  FeatureFlagContext,
  ASSIGNMENT_WORKSPACE_FEATURE,
} from '@xbcb/feature-flags';
import ResolveWorkOrderTaskEscalationModal from 'components/ResolveWorkOrderTaskEscalationModal';
import EscalationMemo from '../EscalationMemo';
import { getCountryAbbreviationFromCustomsBrokerId } from '@xbcb/core';
import { euCountries } from 'types/euCountriesList';
import EscalateWorkOrderTaskModal from 'components/EscalateWorkOrderTask/component';
import CreateExceptionTaskModal from 'components/CreateExceptionTask';
import { getEnv, safeGetMessage } from '@xbcb/ui-utils';
import { isBrexitEnabledForBroker } from '@xbcb/party-utils';
import { getImportCountryCodeForCuDe } from 'libs/getImportCountryCodeForCuDe';
import { CustomsDeclaration } from '__generated__/graphql';

export type WorkOrderProps = AppRecordProvidedProps & {
  loading?: boolean;
  record?: any;
  recordType?: RecordType;
  // returns additional data to display in the "Work Order Info" card
  getAdditionalWorkOrderInfo?: ({
    record,
  }: {
    record?: any;
  }) => WorkOrderInfo[];
  // returns additional cards to display below the "Work Order Info" card
  getAdditionalInfoCards?: ({ record }: { record?: any }) => React.ReactNode[];
  disableCancel?: boolean;
};

// These are the props injected by the WorkOrder higher-order component
export type WorkOrderProvidedProps = {
  getTabs: () => WorkOrderTab[];
  tabs: WorkOrderTab[];
  tasks: any[];
  activeTab: QueryString;
  setActiveTab: (value: string) => void;
  taskEvaluatedState?: WorkOrderTaskEvaluatedState;
};

// These are the total props provided to the Work Order child components, the sum of AppRecord's and WorkOrder's injected props.
export type WorkOrderRecordProps = WorkOrderProvidedProps &
  AppRecordProvidedProps;

// N.B. WorkOrder gets some of its props injected by AppRecord(Create)
const WorkOrder: React.FC<WorkOrderProps> = (props) => {
  const [workOrderBundle] = useBundle('components.WorkOrder');
  const {
    children,
    getAdditionalInfoCards,
    getAdditionalWorkOrderInfo,
    ...threadedPropsFromAppRecord // AppRecord gives WorkOrder props, WorkOrder passes them down to its child component.
  } = props;
  const { loading, record, recordType } = threadedPropsFromAppRecord;
  const history = useHistory();
  const currentUser = useCurrentUser();
  const customsBrokerId = currentUser.customsBroker?.id;
  const countryOfOperation =
    customsBrokerId &&
    getCountryAbbreviationFromCustomsBrokerId(customsBrokerId);
  const isEnabledInEU = euCountries.includes(countryOfOperation);
  // TODO: remove operator from context
  const context: FeatureFlagContext = {
    stage: uiStageToBackendStage[getEnv().stage],
    operator: isEnabledInEU ? currentUser.id : undefined,
  };
  const showAssignmentWorkspaceTableButton =
    isBrexitEnabledForBroker(currentUser.customsBroker) ||
    ASSIGNMENT_WORKSPACE_FEATURE.isEnabled(
      'ASSIGNMENT_WORKSPACE_FEATURE',
      context,
    );
  const additionalInfoCards = getAdditionalInfoCards?.({ record }) || [];
  const additionalWorkOrderInfo =
    getAdditionalWorkOrderInfo?.({ record }) || [];

  const {
    deadline,
    milestones = [],
    status = '',
    tasks = [],
    pgaDispositions = [],
    confirmations,
    group = {},
    inquiries = [],
  } = record || {};

  const [workOrderTaskType, setWorkOrderTaskType] = useWorkOrderTaskType();
  const [activeTab, setActiveTab] = useQueryParams('tab');
  const [taskEvaluatedState, setTaskEvaluatedState] = useState<
    WorkOrderTaskEvaluatedState | undefined
  >();

  if (
    workOrderTaskType &&
    !tasks.some(
      ({ definition }: any) =>
        definition.workOrderTaskType === workOrderTaskType,
    )
  ) {
    // workOrderTaskType is set in the query string, but it's not valid (not one of the tasks types defined in record.tasks). Let's remove it (and display the overall WorkOrder view)
    // setWorkOrderTaskType(
    //   WorkOrderTaskType.US_CONSUMPTION_ENTRY_DOCUMENT_TAGGING,
    // );
    // console.log(
    //   'SET WORK ORDER TASK TYPE TO BE ' +
    //     WorkOrderTaskType.US_CONSUMPTION_ENTRY_DOCUMENT_TAGGING,
    // );
    setWorkOrderTaskType(undefined);
  }

  // For use cases where you need to re-compute tabs based on new form data.
  const getTabs = () => {
    const importCountryCode = getImportCountryCodeForCuDe(
      record as CustomsDeclaration,
    );
    const keyForTabConfiguration = getKeyForWorkOrderTabConfiguration(
      recordType,
      group?.forwarders,
      importCountryCode,
    );
    if (recordType && keyForTabConfiguration) {
      const tabConfiguration = getWorkOrderTabConfiguration(
        recordType,
        keyForTabConfiguration,
        importCountryCode,
      );
      const taskTypeKeyForTabConfiguration = (workOrderTaskType ||
        DEFAULT) as keyof typeof tabConfiguration;
      let tabs = tabConfiguration[taskTypeKeyForTabConfiguration];

      if (
        !currentUser.loading &&
        !checkAccess(currentUser, RecordType.DOCUMENT, UserPermissions.READ)
      ) {
        tabs = without(tabs, WorkOrderTab.Documents);
      }
      if (
        !currentUser.loading &&
        currentUser.accountType !== AccountType.OPERATOR
      ) {
        // Only operator users should see the `Tasks` and `Bond` tabs
        tabs = without(tabs, WorkOrderTab.Tasks);
        tabs = without(tabs, WorkOrderTab.Bond);
        tabs = without(tabs, WorkOrderTab.CommercialInvoices);
      }
      return tabs;
    } else {
      throw new Error(
        `No work order tab configuration found for ${recordType}`,
      );
    }
  };

  const tabs = getTabs();

  if (!tabs.includes(activeTab as WorkOrderTab)) {
    // if the activeTab in the query string isn't one of the available tabs computed for this page, send them to the first available tab.
    setActiveTab(tabs[0]);
  }

  const formattedDeadline = deadline
    ? moment(deadline).format('MM/DD/YYYY hh:mm a')
    : 'N/A';

  const workOrderTask = find(
    tasks,
    (task) => task.definition.workOrderTaskType === workOrderTaskType,
  );

  const handleAssignmentsRedirect = () => history.push('/assignments');
  const handleAssignmentWorkspaceRedirect = () =>
    history.push('/assignment-workspace');

  const assignmentTableButton = (
    <Button
      type="primary"
      key="assignments"
      onClick={handleAssignmentsRedirect}
    >
      {safeGetMessage(workOrderBundle, 'go_to_assignments')}
    </Button>
  );

  const assignmentWorkspaceTableButton = (
    <Button
      type="primary"
      key="assignmentsWorkspace"
      onClick={handleAssignmentWorkspaceRedirect}
    >
      {safeGetMessage(workOrderBundle, 'go_to_assignment_workspace')}
    </Button>
  );

  const workOrderInfo = [
    {
      label: safeGetMessage(workOrderBundle, 'deadline'),
      value: formattedDeadline,
    },
    {
      label: safeGetMessage(workOrderBundle, 'work_order_status'),
      value: capitalCase(status),
    },
    ...additionalWorkOrderInfo,
  ];

  const infoCards = [
    <WorkOrderInfoCard loading={loading} workOrderInfo={workOrderInfo} />,
    ...additionalInfoCards,
  ];

  // TODO we use this cloning logic here, in AppRecord, and in AppRecordCreate. Consolidate.
  const childrenWithProps = React.Children.map(
    React.Children.only(children), // util method that ensures only one child is passed.
    (child) => {
      if (React.isValidElement(child)) {
        // These are all the additional props that `WorkOrder` provides to the component it is rendering (the child component passed in)
        const providedProps: WorkOrderRecordProps = {
          getTabs,
          tabs,
          tasks,
          activeTab,
          setActiveTab,
          taskEvaluatedState,
          ...threadedPropsFromAppRecord,
        };
        return React.cloneElement(child, providedProps);
      } else {
        return child;
      }
    },
  );

  const isCompleted = workOrderTask?.status === WorkOrderTaskStatus.COMPLETED;
  const isCanceled = workOrderTask?.status === WorkOrderTaskStatus.CANCELED;

  return (
    <>
      <AssignWorkOrderTaskModal record={record} />
      <CancelWorkOrderModal record={record} />
      <CompleteWorkOrderTaskModal taskStatus={workOrderTask?.status} />
      <EscalateWorkOrderTaskModal recordId={record.id} />
      <ResolveWorkOrderTaskEscalationModal
        currentUser={currentUser}
        record={record}
        workOrderTaskType={workOrderTaskType as WorkOrderTaskType}
        workOrderTasks={tasks}
      />
      <CreateExceptionTaskModal record={record} />
      <PageContent
        rightContent={
          <RightContentSpace>
            <EscalationMemo
              tasks={tasks}
              workOrderTaskType={workOrderTaskType}
              currentUser={currentUser}
              record={record}
            />
            <WorkOrderTabs
              record={record}
              recordType={recordType}
              additionalInfoCards={infoCards}
              loading={loading}
              milestones={milestones}
              confirmations={confirmations}
              pgaDispositions={pgaDispositions}
              taskId={workOrderTask?.id}
              inquiries={inquiries}
              setTaskEvaluatedState={setTaskEvaluatedState}
            />
          </RightContentSpace>
        }
      >
        {loading ? (
          <Skeleton active />
        ) : isCompleted ? (
          <StyledResult
            status="success"
            title={safeGetMessage(workOrderBundle, 'task_completed')}
            extra={
              showAssignmentWorkspaceTableButton
                ? assignmentWorkspaceTableButton
                : assignmentTableButton
            }
          />
        ) : isCanceled ? (
          <StyledResult
            status="error"
            title={safeGetMessage(workOrderBundle, 'task_canceled')}
            extra={
              showAssignmentWorkspaceTableButton
                ? assignmentWorkspaceTableButton
                : assignmentTableButton
            }
          />
        ) : (
          childrenWithProps
        )}
      </PageContent>
    </>
  );
};

export default WorkOrder;
